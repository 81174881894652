/**
 * RECORD-SIDEBAR
 *
 * Style for record sidebar component
 */

/* ------------------------------------ *\
   #RECORD-SIDEBAR
\* ------------------------------------ */

.ras-record-sidebar {
    transition: all $transition-durations ease-in;
    background: #ffffff;

    .ras-record-sidebar__header {
        display: flex;
        align-items: center;
        height: $header-record-height;
        padding: $spacing-unit;
        border-bottom: 1px solid $color-border-normal;
        transition: all $transition-durations ease-in;
    }

    .ras-logo {
        margin-right: $spacing-unit;
    }

    .ras-btn-wrapper {
        .ras-btn-action {
            padding: $spacing-unit;
        }
    }
}


/* ------------------------------------ *\
   #MEDIA-QUERIES
\* ------------------------------------ */

@media all and (max-width: $breakpoint-ipad-portrait - 1) {
    .ras-record-sidebar {
        .ras-btn-action {
            &.ras-mobile-hidden {
                display: none;
            }
        }

        .ras-btn-wrapper {
            position: absolute;
            right: 0;
            padding: $spacing-unit;
        }

        .ras-record-sidebar__header {

        }

        .ras-btn-action--has-label {
            padding: $spacing-unit;
            position: absolute;
            top: $header-record-height-mobile + $spacing-unit*3;
            right: $spacing-unit;
        }
    }
}

@media all and (min-width: $breakpoint-ipad-portrait) {
    .ras-record-sidebar {
        border-right: 1px solid $color-border-normal;

        .ras-record-sidebar__header {
            width: 100%;
            //padding-left: $spacing-unit*2;

            .ras-btn-action--has-label {
                align-self: stretch;
                height: 100%;
                margin-left: auto;
                padding-left: $spacing-unit;
                border-left: 1px solid $color-border-normal;
            }
        }

        section:not(.has-sidebar-closed) & {
            .ras-record-sidebar__header {
                padding-left: $spacing-unit*2;
            }
        }

        .ras-btn-wrapper {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            padding: $spacing-unit*2 0;

            .ras-btn-action {
                margin-bottom: $spacing-unit*2;
                padding: $spacing-unit*2 $spacing-unit;
            }
        }

        &.is-closed {

        }

        .has-sidebar-closed & {
            .ras-logo {
                margin-right: 0;
            }
        }
    }

}

@media all and (min-width: $breakpoint-ipad-portrait) and (max-width: $breakpoint-laptop-wide - 1) {
}

@media all and (min-width: $breakpoint-laptop-wide) {

}
