/**
 * MAP-TOOLTIP
 *
 * Style for map tooltips and components
 */

/* ------------------------------------ *\
   #MAP-TOOLTIP
\* ------------------------------------ */

.ras-tooltip {
    padding: $spacing-unit;

    .ras-tooltip__content{
        min-width: 200px;
    }

    .ras-tooltip__footer {
        display: flex;
        justify-content: space-between;
        padding-top: $spacing-unit;
        border-top: 1px solid $color-border-normal;

        .ras-btn {
            width: 100%;
            max-width: initial;
        }
    }

    .ras-tooltip__track-description {
        line-height: 1.2;
        font-size: $font-size-xs;

        [class*="fa"] {
            margin-right: $spacing-unit/2;
            color: $color-fourth;
            font-size: $font-size-s
        }
    }

    .ras-tooltip__track-title {
        padding: $spacing-unit*1.5 0 $spacing-unit;
    }
}


/* ------------------------------------ *\
   #MEDIA-QUERIES
\* ------------------------------------ */

@media all and (max-width: $breakpoint-ipad-portrait - 1) {
}

@media all and (min-width: $breakpoint-ipad-portrait) {
    .ras-tooltip {
        padding: $spacing-unit*2;

        .ras-tooltip__track-description {
            font-size: $font-size-s;

            [class*="fa"] {
                font-size: $font-size-m;
            }
        }

        .ras-tooltip__track-title {
            padding: $spacing-unit*2 0 $spacing-unit*1.5;
        }
    }

}

@media all and (min-width: $breakpoint-ipad-portrait) and (max-width: $breakpoint-laptop-wide - 1) {
}

@media all and (min-width: $breakpoint-laptop-wide) {
}
