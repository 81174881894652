/**
 * RECORD-FILTERS
 *
 * Style for record filters and facets
 */


/* ------------------------------------ *\
   #RECORD-FILTERS
\* ------------------------------------ */

.ras-record-filters {
    padding: $spacing-unit;
}


/* Filters pills */

.ras-record-filter-pills {
    display: flex;
    flex-flow: row wrap;
    padding-bottom: $spacing-unit/2;
    border-bottom: 2px solid $color-text;
    &.selezionati {
        padding: 12px 0;
    }
    .ras-record-filter-pills__item {
        padding: $spacing-unit/5;
        //flex: 0 0 25%;
        //max-width: 25%;
        flex: 0 0 50%;
        &.ng-star-inserted {
            flex: 0 0 100%;
            background-color: transparent;
            label {
                text-align: left;
                background-color: #16497b;
                color: #fff;
                line-height: 21px;
                i {
                    float: right;
                    line-height: 21px;
                }
            }
        }
        input[type="radio"] {
            display: none;
            &:checked+label {
                color: #ffffff;
                background: $color-text;
            }
        }
        label {
            display: inline-block;
            width: 100%;
            padding: $spacing-unit/2;
            border-radius: $spacing-unit/2;
            cursor: pointer;
            background: $color-background-light;
            text-align: center;
            font-size: $font-size-xs;
        }
        &.ras-record-filter-pills__clear {
            font-weight: 800;
        }
    }
}


/* Facets */

.ras-record-facets {
    padding: $spacing-unit 0;
    border-bottom: 2px solid $color-text;
    .ras-record-facets__title {
        padding-bottom: $spacing-unit;
        text-transform: uppercase;
        font-size: $font-size-m;
    }
    .ras-record-facets__item {
        display: flex;
        padding: $spacing-unit;
        border-bottom: 1px solid $color-border-normal;
        color: $color-text;
        font-size: $font-size-s;
        .ras-record-facets__item-count {
            margin-left: auto;
            display: inline-block;
            padding: $spacing-unit/5 $spacing-unit/2;
            //border-radius: 40%;
            background: $color-second-light;
            color: #ffffff;
            font-size: $font-size-xs;
            transition: all $transition-durations ease-in;
            border-radius: 10px;
            background: rgba(22, 73, 123, 0.6);
            color: #ffffff;
            font-size: 0.75rem;
            height: 16px;
            min-width: 16px;
            text-align: center;
            line-height: 16px;
        }
        &:hover,
        &:focus {
            color: $color-text-hover;
            .ras-record-facets__item-count {
                background: $color-text-hover;
            }
        }
    }
    .ras-record-facets_activeFilter {
        font-weight: 800;
    }
    /* Angular material expansion component overrides */
    .mat-expansion-panel-header {
        height: 45px !important;
        padding: 0 $spacing-unit;
        font-family: $font-family-main;
        background: $color-background-light;
        color: $color-text-dark;
        &.mat-expanded {
            &:hover,
            &:focus {
                background: $color-background-light;
            }
        }
    }
    .mat-expansion-panel-content {
        font-family: $font-family-main;
    }
    .mat-expansion-panel {
        margin-bottom: $spacing-unit * 2;
        &:last-of-type {
            margin-bottom: 0;
        }
        &:not([class*="mat-elevation-z"]) {
            box-shadow: none;
        }
    }
    .mat-expansion-panel-body {
        padding: 0;
    }
    .mat-accordion .mat-expansion-panel:not(.mat-expanded),
    .mat-accordion .mat-expansion-panel:not(.mat-expansion-panel-spacing) {
        border-radius: 4px;
    }
}


/* ------------------------------------ *\
   #MEDIA-QUERIES
\* ------------------------------------ */

@media all and (max-width: $breakpoint-ipad-portrait - 1) {
    .ras-record-filters {
        @include mobile-filters-panel;
        background: #ffffff;
    }
}

@media all and (min-width: $breakpoint-ipad-portrait) {
    .ras-record-filters {
        height: calc(100vh - #{$header-record-height} - 1px);
        padding: $spacing-unit * 2;
        overflow-y: auto;
        .ras-filters-btn-mobile {
            display: none;
        }
    }
    .ras-record-filter-pills {
        padding-bottom: $spacing-unit;
        .ras-record-filter-pills__item {
            //padding: $spacing-unit/2;
            //input[type="checkbox"] {
            //
            //    &:checked + label {
            //
            //    }
            //}
            label {
                //font-size: $font-size-s;
            }
        }
    }
    .ras-record-facets {
        padding: $spacing-unit * 1.5 0;
        .ras-record-facets__title {
            padding-bottom: $spacing-unit * 1.5;
        }
    }
}

@media all and (min-width: $breakpoint-ipad-portrait) and (max-width: $breakpoint-laptop-wide - 1) {}

@media all and (min-width: $breakpoint-laptop-wide) {}