/**
 * DASHBOARD-FILTERS
 *
 * Style for dashboard filters component
 */


/* ------------------------------------ *\
   #DASHBOARD-FILTERS
\* ------------------------------------ */

.ras-dashboard-filters {
    padding: $spacing-unit;
    background: $color-background-light;
    color: $color-text-dark;
    .ras-dashboard-filters__action-button {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
        line-height: 1;
        color: $color-second-light;
        &:hover,
        &:focus,
        &.is-active {
            color: $color-text;
        }
        .view-more {}
        .view-less {
            display: none;
        }
        &.is-active {
            @include linear-gradient(to bottom, #ffffff, transparent);
            .view-more {
                display: none;
            }
            .view-less {
                display: inline-block;
            }
        }
        span {
            padding: 0 $spacing-unit;
            font-weight: $font-weight-bold;
            font-size: $font-size-s;
        }
        [class*="fa"] {
            font-size: $font-size-ml;
        }
    }
    .ras-dashboard-filters__action {
        &:not(.ras-dashboard-filters__action-delete) {
            span {
                margin-bottom: $spacing-unit/2;
            }
        }
    }
    .ras-dashboard-filters__action-delete {
        span {
            margin-top: $spacing-unit/2;
        }
    }
    .ras-dashboard-filters__wrapper {
        .ras-checkbox {
            label {
                font-size: $font-size-s;
            }
        }
    }
    //.ras-dashboard-filters__wrapper-others,
    //.ras-dashboard-filters__action-delete {
    //    position: absolute;
    //    transition: all $transition-durations ease-in;
    //    @include hidden-element;
    //
    //    &.is-open {
    //        position: relative;
    //        @include visible-element(auto);
    //    }
    //}
}


/* Active filters */

.ras-dashboard-filters-close {
    //position: absolute;
    right: 44px;
    background: $color-gray-03;
    padding: 3px 10px;
    border-radius: 13px;
    color: #FFF;
    margin-left: 8px;
    &:hover {
        color: $color-gray-00;
        background: $color-gray-05;
    }
}

.ras-dashboard-active-filters {
    display: flex;
    color: #ffffff;
    .ras-dashboard-active-filters__item {
        display: inline-flex;
        align-items: center;
        margin-right: $spacing-unit/2;
        margin-bottom: $spacing-unit/2;
        padding: $spacing-unit/2;
        border-radius: 20px;
        background: $color-background-medium;
        white-space: nowrap;
        font-weight: $font-weight-bold;
        font-size: $font-size-xxs;
    }
    .ras-dashboard-active-filters__item-close {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: $spacing-unit*2;
        height: $spacing-unit*2;
        margin-left: $spacing-unit;
        background: #ffffff;
        border-radius: 50%;
        color: $color-background-medium;
        font-size: $font-size-xs;
    }
}


/* ------------------------------------ *\
   #MEDIA-QUERIES
\* ------------------------------------ */

@media all and (max-width: $breakpoint-ipad-portrait - 1) {
    .ras-dashboard-filters {
        .ras-dashboard-filters__wrapper {
            .ras-form-item {
                margin-bottom: $spacing-unit*2;
            }
        }
        .ras-dashboard-filters__action:not(ras-dashboard-filters__action-delete) {
            display: none;
        }
        .ras-dashboard-filters__form {
            display: flex;
        }
        .ras-search-autocomplete {
            flex: 1;
            padding-right: $spacing-unit*2;
        }
        .ras-btn-action {
            margin: $spacing-unit 0 $spacing-unit auto;
        }
        .ras-dashboard-filters__wrapper {
            @include mobile-filters-panel;
        }
    }
    .ras-dashboard-active-filters {
        overflow-x: auto;
        margin-bottom: $spacing-unit;
    }
}

@media all and (min-width: $breakpoint-ipad-portrait) {
    .ras-dashboard-filters {
        padding: $spacing-unit*2;
        .is-scrolled & {
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            z-index: $ras-leaflet-components-z-index + 1;
        }
        .ras-form-item {
            @include flex-item-width(18%);
            margin-right: 2%;
            &:last-of-type {
                margin-right: 0;
            }
        }
        .ras-search-autocomplete {
            @include flex-item-width(100%);
        }
        form {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-end;
        }
        .ras-dashboard-filters__wrapper {
            display: flex;
            align-items: flex-end;
            flex-flow: row wrap;
            @include flex-item-width(100%);
            padding: 0;
            .ras-checkbox {
                label {
                    font-size: $font-size-m;
                }
            }
        }
        .ras-dashboard-filters__row {
            display: flex;
            align-items: flex-end;
            padding: 0 $spacing-unit;
            //@include  flex-item-width(85%);
            flex: 0 0 85%;
        }
        .ras-dashboard-filters__action {
            @include flex-item-width(15%);
            &:not(.ras-dashboard-filters__action-delete) {
                .ras-dashboard-filters__action-button {
                    border-left: 2px solid $color-border-light;
                }
            }
        }
        .ras-dashboard-filters__row-others,
        .ras-dashboard-filters__action-delete {
            position: absolute;
            transition: all $transition-durations ease-in;
            @include hidden-element;
            &.is-open {
                position: relative;
                @include visible-element(auto);
            }
        }
    }
    .ras-dashboard-active-filters {
        flex-flow: row wrap;
        max-height: 60px;
        padding: 0 0 $spacing-unit;
        .ras-dashboard-active-filters__item {
            padding: $spacing-unit/5 $spacing-unit/2;
            font-size: $font-size-xs;
        }
        .ras-dashboard-active-filters__item-close {
            width: $spacing-unit*1.5;
            height: $spacing-unit*1.5;
            margin-left: $spacing-unit/5;
            font-size: $font-size-xs;
        }
    }
}

@media all and (min-width: $breakpoint-ipad-portrait) and (max-width: $breakpoint-laptop-wide - 1) {
    .ras-dashboard-filters {
        .ras-dashboard-filters__row,
        .ras-dashboard-filters__action {
            margin-top: $spacing-unit*2;
        }
    }
}

@media all and (min-width: $breakpoint-laptop-wide) {
    .ras-dashboard-filters {
        .ras-search-bar {}
        .ras-search-autocomplete {
            @include flex-item-width(20%);
            padding-right: $spacing-unit*2;
        }
        .ras-dashboard-filters__wrapper {
            //@include  flex-item-width(80%);
            flex: 1;
        }
        .ras-dashboard-filters__row {
            padding: 0 $spacing-unit*2;
            //@include  flex-item-width(85%);
        }
        .ras-dashboard-filters__action {
            //@include  flex-item-width(15%);
        }
        .ras-dashboard-filters__action-delete,
        .ras-dashboard-filters__row-others {
            margin-top: $spacing-unit*2;
            padding-top: $spacing-unit*2;
        }
        .ras-dashboard-filters__row-others {
            &.is-open {
                border-left: 2px solid $color-border-normal;
            }
        }
    }
    .ras-dashboard-active-filters {
        padding: 0;
    }
}