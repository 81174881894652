/**
 * LOGIN
 *
 * Login layout
 */

/* ------------------------------------ *\
   #LOGIN
\* ------------------------------------ */

.ras-wrapper-centered-login {
  .ras-logo {
    display: inline-block;
    width: auto;
    height: auto;
    //padding: $spacing-unit*2;
    width: 200px;
    height: 60px;
    background-image: url(assets/images/logo.svg);
    background-position: center;
    background-repeat: no-repeat;
  }
}

.ras-login {
  min-height: 250px;
  min-width: 350px;
  .ras-login-panel {
    padding: $spacing-unit * 3;
    border: 1px solid $color-border-normal;
    border-radius: 5px;
  }
}

.ras-logo-interreg {
  max-width: 315px;
  margin: 40px 0 0 0;
}

.dark-backdrop {
  background: $color-gray-05;
  opacity: 0.6 !important;
}


