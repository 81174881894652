/**
 * UTILITIES
 *
 * Utilities classes and styles
 */

/* ------------------------------------ *\
   #TEXT-ELLIPSIS
\* ------------------------------------ */

.ras-text-ellipsis {
    &::after {
        content: '\2026';
    }
}


/* ------------------------------------ *\
   #HIDDEN-LABEL
\* ------------------------------------ */

.ras-hidden-label {
    @include accessible-text;
}


/* ------------------------------------ *\
   #EMPTY-STATES
\* ------------------------------------ */

.empty-state {
    font-weight: $font-weight-normal;
    font-size: $font-size-l;
    line-height: 1.3;
}
