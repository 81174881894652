/**
 * RECORD-MAP
 *
 * Style for record map component
 */


/* ------------------------------------ *\
   #RECORD-MAP
\* ------------------------------------ */

.ras-record-map {
    .ras-record-map__header {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        padding-right: $spacing-unit;
        padding-left: $spacing-unit;
        background: $color-background-light;
        border-bottom: 1px solid $color-border-normal;
        transition: width $transition-durations ease-in;
    }
    .ras-record-features {
        display: flex;
        flex-flow: row wrap;
        .ras-record-features__item {
            //padding-bottom: $spacing-unit/2;
            padding-right: $spacing-unit * 1.5;
            font-size: $font-size-xs;
        }
        i {
            margin-right: $spacing-unit/2;
            font-size: $font-size-m;
            color: $color-card-icons;
        }
        .ras-icon-group {
            margin-right: $spacing-unit/5;
            i {
                margin-right: $spacing-unit/5;
                font-size: $font-size-xxs;
            }
        }
    }
    .ras-header__user-area {
        margin-left: auto;
        li {
            align-self: stretch;
        }
    }
    .ras-record-map__map {
        flex: 1;
    }
    .ras-record-title {
        margin-bottom: 0;
        align-self: center;
        font-size: $font-size-intermedium;
        font-weight: $font-weight-medium;
        display: inline-block; ///FRA ////
        .fa-exclamation-triangle {
            font-size: 21px;
            line-height: 30px;
            margin-right: 6px;
            margin-bottom: 4px;
            color: #ffa700;
        }
    }
    .ras-lang-tab {
        margin: 0;
        padding: 0;
        float: right;
        li {
            display: inline-block;
            .langtab {
                background-color: #ccc;
                display: block;
                height: 30px;
                line-height: 30px;
                width: 30px;
                text-align: center;
                margin-left: 6px;
                border-radius: 4px;
                color: #fff;
                font-size: 13px;
                font-weight: 800;
                position: relative;
                cursor: pointer;
                &.completo {
                    background-color: #6ea212;
                }
                &.noncompleto {
                    background-color: #e6a100;
                }
                &.vuoto {
                    background-color: #ccc;
                }
                .alertlang {
                    position: absolute;
                    top: -6px;
                    right: -6px;
                    background-color: #c00;
                    border-radius: 50%;
                    height: 12px;
                    width: 12px;
                    border: 1px solid #fff;
                    display: block;
                }
            }
        }
    }
    .ras-search-autocomplete {
        margin-right: $spacing-unit;
        input[type="text"] {
            height: $form-input-medium-height;
        }
    }
    .ras-record-map__btn {
        display: flex;
        align-items: center;
        height: 100%;
        margin: 0 $spacing-unit;
        align-self: stretch;
        border-bottom: 6px solid $color-second-light;
        color: $color-second-light;
        font-size: $font-size-s;
        [class*="fa"] {
            margin-right: $spacing-unit/2;
            font-size: $font-size-intermedium;
        }
        &.is-active {
            color: $color-text;
            border-bottom-color: $color-text;
        }
        &:hover,
        &.is-active:hover,
        &:focus,
        &.is-active:focus {
            color: $color-text-hover;
            border-bottom-color: $color-text-hover;
        }
    }
    .ras-record-map__details {
        padding: $spacing-unit;
        transition: all $transition-durations ease-in;
    }
    .ras-record-map__title {
        padding: $spacing-unit 0;
        text-align: center;
        text-transform: uppercase;
        font-size: $font-size-xs;
    }
    .ras-record-map__add-track {
        margin-top: $spacing-unit;
        padding: $spacing-unit * 2 0;
        text-align: center;
    }
    .ras-record-map__infos {
        padding: $spacing-unit;
    }
    .ras-record-map__infos-text {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        margin: $spacing-unit * 3 0 0 0;
    }
    .ras-record-map__attachments {
        .ras-btn {
            min-width: 186px;
            width: calc(50% - 24px);
            margin: 6px;
            background-color: $color-gray-04;
            &:hover {
                background-color: $color-gray-05;
            }
        }
        input.hidden {
            display: none;
        }
    }
    .ras-record-map__infos-detail {
        margin-right: $spacing-unit/2;
        color: $color-third;
        font-weight: $font-weight-bold;
        font-size: $font-size-xs;
        display: inline-block;
    }
    .ras-record-map__infos-value {
        color: $color-second;
        font-size: $font-size-xxs;
        display: inline-block;
        margin-right: 2px;
    }
    .ras-record-map__infos-definition {
        margin-left: auto;
        font-size: $font-size-xs;
    }
    .ras-record-map__infos-description {
        display: flex;
        //@include flex-item-width(calc(100% - 45px));
        @include flex-item-width(100%);
        padding-top: $spacing-unit/2;
        padding-right: $spacing-unit;
        font-size: $font-size-xs;
        p {
            line-height: 1.4;
            font-size: inherit;
        }
        form {
            &.ng-pristine {
                width: 100%;
            }
        }
    }
    .ras-record-map__final-step {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $spacing-unit/2 0;
        position: relative;
    }
    .ras-record-map__tappa {
        min-height: 40px;
        border-radius: 3px;
        border: $color-gray-00 solid 1px;
        margin-bottom: $spacing-unit * 4;
        a {
            color: $color-gray-00;
        }
        &.ras-record-map__tappaSelected {
            border: $color-main solid 3px;
            a {
                color: $color-main;
                &.ras-btn {
                    color: $color-button-text-hover;
                }
            }
            .ras-record-map__add-step {
                &.ras-record-map__add-step-day {
                    &::before {
                        background: $color-main;
                    }
                    .ras-record-map__add-step-label {
                        border: 1px solid $color-main;
                        border-radius: 5px;
                    }
                }
            }
        }
        .ras-record-map__add-step {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: $spacing-unit/2 0;
            position: relative;
            &::before {
                content: "";
                display: block;
                height: 2px;
                position: absolute;
                @include vertical-centering;
                right: 0;
                left: 0;
                background: $color-border-normal;
                z-index: 1;
            }
            .ras-record-map__add-step-label {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: $spacing-unit/2 $spacing-unit;
                position: relative;
                z-index: 2;
                background: #ffffff;
                font-weight: $font-weight-bold;
                font-size: $font-size-s;
                [class*="fa"] {
                    margin-right: $spacing-unit/2;
                }
            }
            &.ras-record-map__add-step-day {
                &::before {
                    background: $color-gray-00;
                }
                .ras-record-map__add-step-label {
                    border: 1px solid $color-gray-00;
                    border-radius: 5px;
                }
            }
        }
    }
}


/* ------------------------------------ *\
   #MEDIA-QUERIES
\* ------------------------------------ */

@media all and (max-width: $breakpoint-ipad-portrait - 1) {
    .ras-record-map {
        display: flex;
        flex-flow: column nowrap;
        height: calc(100vh - #{$header-record-height});
        section:not(.ras-record--add-track) & {
            .ras-record-map__header {
                min-height: $header-record-height;
                padding: $spacing-unit;
            }
        }
        .ras-record-map__header {
            padding: $spacing-unit;
        }
        .ras-record--add-track & {
            .ras-search-autocomplete {
                margin-bottom: $spacing-unit;
            }
        }
        .ras-header__user-area {
            position: absolute;
            top: $spacing-unit/2;
            right: $spacing-unit;
        }
        .ras-record-title {
            flex: 0 0 100%;
            max-width: 100%;
            padding: $spacing-unit * 2 0 $spacing-unit;
            border-top: 1px solid $color-border-normal;
        }
        .ras-search-autocomplete {
            //position: absolute;
            //top: $header-record-height + $spacing-unit;
            //right: 0;
        }
        .ras-record-title-wrapper {
            border-top: 1px solid $color-border-normal;
        }
        .ras-record-map__main {
            flex: 1;
        }
        .ras-record-map__map {
            height: 100%;
            .leaflet-container {
                height: 100%;
            }
        }
        .ras-action-wrapper {
            padding: $spacing-unit;
            position: fixed;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: $ras-modal-z-index;
            background: #ffffff;
        }
        .ras-record--add-track &,
        .ras-record--expanded & {
            .ras-record-map__main {
                display: flex;
                flex-flow: column nowrap;
            }
            .ras-record-map__header {
                min-height: $header-record-height;
                padding: $spacing-unit;
            }
            .ras-record-map__map,
            .ras-record-map__details {
                flex: 1;
            }
            .ras-record-map__map {
                height: 50%;
            }
            .ras-record-map__details {
                padding-bottom: $spacing-unit * 6;
                overflow-y: auto;
                @include scroll-gradient;
            }
        }
    }
}

@media all and (min-width: $breakpoint-ipad-portrait) {
    .ras-record-map {
        .ras-record-map__header {
            width: 100%;
            height: $header-record-height;
        }
        .ras-search-autocomplete {
            @include flex-item-width(25%);
        }
        .ras-record-title-wrapper {
            flex: 1;
        }
        .ras-record-title {
            font-size: $font-size-ml;
        }
        .ras-record-map__btn {
            font-size: $font-size-s;
            [class*="fa"] {
                margin-right: $spacing-unit/2;
                font-size: $font-size-ml;
            }
        }
        .ras-record-map__map {
            height: calc(100vh - #{$header-record-height} - 1px);
        }
        .ras-record-map__details {
            display: flex;
            flex-flow: column nowrap;
            padding: $spacing-unit 0;
            position: relative;
            @include scroll-gradient;
        }
        .ras-record-map__scroller {
            padding: 0 $spacing-unit * 2 $spacing-unit * 2;
        }
        .ras-record-map__title {
            padding: $spacing-unit * 2 0;
            font-size: $font-size-s;
        }
        .ras-record-map__add-track {
            margin-top: $spacing-unit * 2;
            padding: $spacing-unit * 3 0;
        }
        .ras-record-map__infos-detail,
        .ras-record-map__infos-definition,
        .ras-record-map__infos-description {
            font-size: $font-size-s;
        }
        .ras-record-map__infos-detail {
            margin-right: $spacing-unit;
            .ras-btn-action {
                width: 30px;
                height: 30px;
                [class*="fa"] {
                    font-size: $font-size-m;
                }
            }
        }
        .ras-record--expanded & {
            .ras-record-map__header {
                width: 40%;
            }
            .ras-search-autocomplete {
                flex: 1;
                max-width: 50%;
            }
            .ras-record-title {
                margin-bottom: $spacing-unit/2;
                font-size: $font-size-ll;
            }
        }
        .ras-record-map__infos-description {
            font-size: $font-size-s;
        }
        .ras-record-map__add-step {
            //padding: $spacing-unit*2 0;
            .ras-record-map__add-step-label {
                font-size: $font-size-m;
            }
        }
    }
}

@media all and (min-width: $breakpoint-ipad-portrait) and (max-width: $breakpoint-desktop - 1) {
    .ras-record-map {
        .ras-record--expanded & {
            .ras-record-title-wrapper {
                margin-bottom: $spacing-unit;
            }
        }
    }
}

@media all and (min-width: $breakpoint-laptop-wide) {}

@media all and (min-width: $breakpoint-desktop) {
    .ras-record-map {
        .ras-record-map__infos {
            //display: flex;
        }
        .ras-record--expanded & {
            .ras-record-title-wrapper {
                padding-right: $spacing-unit * 2;
                //@include flex-item-width(40%);
            }
        }
    }
}

//******* AWESOME MARKERS *********//
i.map-icon-far.fa {
    opacity: 0.6;
}

.awesome-marker-icon-darkred.awesome-marker.leaflet-zoom-animated.leaflet-interactive {
    opacity: 0.5;
}

///***************************/////
.map-cluster-icon {
    font-weight: bold;
    font-size: 1.4em;
    //text-shadow: 1px 1px 1px #666;
    position: absolute;
    top: -6px;
    left: 0px;
    color: $color-gray-01;
    background-color: $color-gray-04;
    border-radius: 50%;
    display: block;
    height: 30px;
    line-height: 30px;
    width: 30px;
    text-align: center;
    //border: 2px solid $color-gray-00;
    //box-shadow: 1px 1px 3px #aaa;
}

.map-path-icon {
    width: 25px !important;
    height: 25px !important;
    background: #000;
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    text-align: center;
    border-radius: 25px;
    position: absolute;
    opacity: 0.8;
    &:hover {
        opacity: 1;
        font-size: 20px;
        width: 30px !important;
        height: 30px !important;
        top: -2.5px;
        left: -2.5px;
    }
    //inizio percorso
    &.map-startpath-icon {
        width: 35px !important;
        height: 35px !important;
        font-size: 23px;
        opacity: 0.8;
        background: transparent;
        text-shadow: 0px 0px 2px #000;
        background: #333;
         ::before {
            // freccia
            content: "\f11e";
        }
        &:hover {
            font-size: 2.2em;
            width: 40px !important;
            height: 40px !important;
            // &::after {
            //   content: attr(data-content);
            //   position: absolute;
            //   top: 40px;
            //   left: 0px;
            //   background: #000;
            //   border-radius: 5px;
            //   padding: 10px;
            //   font-family: "Nunito Sans", sans-serif;
            // }
        }
    }
    //in mezzo
    &.map-middle-icon {
        width: 10px !important;
        height: 10px !important;
        background: #000;
        color: #fff;
        pointer-events: none;
        opacity: 1;
        border: #fff 1.5px solid;
    }
    //TAPPE GENERICHE
    &.map-nextpath-icon {
        // background: #777;
        // top: -6.5px;
        // left: -6.5px;
        top: -20px;
        left: -2px;
        background: transparent;
        font-size: 1.5em;
        opacity: 1;
        text-shadow: 1px 1px 3px #aaa;
        color: #555 !important;
         ::before {
            content: "\f024"; // bandiera
        }
        &:hover {
            // top: -9px !important;
            // left: -9px !important;
            top: -17px !important;
            background: #fff;
            text-shadow: none;
            width: 26px !important;
            height: 26px !important;
            // color: #fff;
             ::before {
                content: "\f067"; // segno +
            }
            // &::after {
            //   content: attr(data-content);
            //   position: absolute;
            //   top: 40px;
            //   left: 0px;
            //   background: #000;
            //   border-radius: 5px;
            //   padding: 10px;
            //   font-family: "Nunito Sans", sans-serif;
            //   min-width: 200px;
            // }
        }
        // tappe ost
        &.map-ost-icon {
            // background: #000;
            // top: -10px;
            top: -25px;
            background: transparent;
            font-size: 2em;
            opacity: 1;
            text-shadow: 1px 1px 3px #aaa;
            color: #222 !important;
            &:hover {
                top: -24px !important;
                left: -10px;
                // background: #fff;
                background: #222;
                color: #fff !important;
                text-shadow: none;
                width: 34px !important;
                height: 34px !important;
            }
            // incroci
            &.map-cross-icon {
                top: -26px !important;
                left: -6px;
                 ::before {
                    content: "\f277"; // paletto incrocio
                }
                &:hover {
                     ::before {
                        content: "\f067"; // segno +
                    }
                }
            }
        }
    }
    // ultimo punto percorso
    &.map-lastpath-icon {
        top: -7px;
        left: -7.5px;
         ::before {
            // cerchietto
            content: "\f192";
        }
        &:hover {
            top: -10px !important;
            left: -10px !important;
            z-index: 1000;
             ::before {
                // segno -
                content: "\f056";
            }
        }
    }
}

.leaflet-marker-icon.leaflet-div-icon.leaflet-zoom-animated.leaflet-interactive {
    border: 0;
    background: transparent;
}