/**
 * CARD
 *
 * Style for card component
 */


/* ------------------------------------ *\
   #CARD
\* ------------------------------------ */

.ras-card {
    margin-bottom: $spacing-unit;
    padding: $spacing-unit $spacing-unit 0 $spacing-unit;
    @include box-shadow;
    border-radius: 2px;
    &.is-selected {
        //@include box-shadow-active;
        box-shadow: 0 0 0 3px red; /// GIBIN///
    }
    .ras-card__header {
        display: flex;
        flex-flow: row wrap;
        align-items: baseline;
        //justify-content: space-between;
        margin-right: -$spacing-unit;
        margin-left: -$spacing-unit;
        padding: 0 $spacing-unit $spacing-unit/2 $spacing-unit;
        border-bottom: 1px solid $color-border-normal;
    }
    .ras-card__body {
        display: flex;
        .ras-card__title {
            .fa-exclamation-triangle {
                font-size: 15px;
                line-height: 30px;
                margin-right: 6px;
                margin-bottom: 4px;
                color: #ffa700;
            }
        }
    }
    .ras-card__content {
        flex: 1;
        padding-top: $spacing-unit;
        padding-bottom: $spacing-unit;
    }
    .ras-card__actions {
        display: flex;
        //flex-flow: column nowrap;
        justify-content: space-around;
        margin-right: -$spacing-unit;
    }
    .ras-card__button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $spacing-unit $spacing-unit * 1.5;
        color: $color-second-light;
        font-size: $font-size-s;
        text-align: center;
        &:hover,
        &:focus {
            color: $color-text-hover;
        }
        &.ras-card__button {
            //border-bottom: 1px solid $color-border-normal;
            color: $color-text;
            font-size: $font-size-intermedium;
            &:hover,
            &:focus {
                color: $color-text-hover;
            }
        }
    }
    .ras-card__feature {
        padding-bottom: $spacing-unit/2;
        padding-right: $spacing-unit * 1.5;
        font-size: $font-size-xxs;
        &.ras-card__feature-disabled {
            opacity: 0.3;
        }
        i {
            margin-right: $spacing-unit/2;
            color: $color-card-icons;
            font-size: $font-size-s;
        }
        .fa-route {
            font-size: $font-size-m;
            color: $color-text;
        }
        .ras-icon-group {
            margin-right: $spacing-unit/2;
            i {
                margin-right: $spacing-unit/5;
                font-size: $font-size-xxs;
            }
        }
    }
    .ras-card__definition {
        font-size: $font-size-xs;
    }
    .ras-card__image {
        @include flex-item-width(33.333%);
        align-items: center;
        margin-left: auto;
        padding: $spacing-unit;
        line-height: 0;
        overflow: hidden;
    }
    .ras-card__details {
        padding: $spacing-unit/5 0;
    }
    .ras-card__details-item {
        margin-right: $spacing-unit;
        font-size: $font-size-xxs;
        font-weight: $font-weight-bold;
        color: $color-third;
        [class*="fa"] {
            margin-right: $spacing-unit/5;
        }
        &.ras-card__details-item-status {
            color: $color-second-light;
        }
    }
    .ras-card__excerpt {
        font-size: $font-size-xs;
    }
}


/* ------------------------------------ *\
   #MEDIA-QUERIES
\* ------------------------------------ */

@media all and (max-width: $breakpoint-laptop-wide - 1) {}

@media all and (max-width: $breakpoint-ipad-portrait - 1) {
    .ras-card {
        .ras-card__header {}
        .ras-card__body {
            flex-flow: row wrap;
        }
        .ras-card__actions {
            border-top: 1px solid $color-border-normal;
        }
        .ras-card__content {
            @include flex-item-width(66.666%);
        }
        .ras-card__actions {
            flex: 1;
        }
    }
}

@media all and (min-width: $breakpoint-ipad-portrait) {
    .ras-card {
        .ras-card__actions {
            flex-flow: column nowrap;
        }
        .ras-card__header {}
        .ras-card__body {}
        .ras-card__actions {}
        .ras-card__button {
            position: relative;
            &.ras-card__button-edit {
                // border-bottom: 1px solid $color-border-normal;
                &::after {
                    content: "";
                    display: block;
                    width: $spacing-unit * 2;
                    height: 1px;
                    background: $color-border-normal;
                    position: absolute;
                    bottom: -$spacing-unit/2;
                }
            }
            &.ras-card__button-copy {
                position: relative;
                &::after {
                    content: "";
                    display: block;
                    width: $spacing-unit * 2;
                    height: 1px;
                    background: $color-border-normal;
                    position: absolute;
                    bottom: -$spacing-unit/2;
                }
            }
            &.ras-card__button-delete {
                position: relative;
            }
        }
    }
}

@media all and (min-width: $breakpoint-ipad-portrait) and (max-width: $breakpoint-laptop-wide - 1) {}

@media all and (min-width: $breakpoint-laptop-wide) {
    .ras-card {
        padding: $spacing-unit * 2 $spacing-unit * 2 0 $spacing-unit * 2;
        .ras-card__definition,
        .ras-card__details-item,
        .ras-card__feature,
        .ras-card__excerpt {
            font-size: $font-size-s;
        }
        .ras-card__feature {
            i {
                margin-right: $spacing-unit;
                font-size: $font-size-intermedium;
            }
            .fa-route {
                font-size: $font-size-l;
            }
            .ras-icon-group {
                i {
                    //font-size: $font-size-xxs;
                }
            }
        }
        .ras-card__details {
            padding: $spacing-unit 0;
        }
        .ras-card__actions {
            margin-right: -$spacing-unit * 2;
        }
        .ras-card__button {
            padding: $spacing-unit $spacing-unit * 1.5;
            color: $color-second-light;
            font-size: $font-size-s;
            text-align: center;
            &:hover,
            &:focus {
                color: $color-text-hover;
            }
        }
    }
}

@media all and (min-width: $breakpoint-desktop) {
    .ras-card {
        .ras-card__image {
            @include flex-item-width(211px);
        }
        .ras-card__title-container {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }
    }
}