/**
 * DASHBOARD-MAIN
 *
 * Style for dashboard main layout
 */


/* ------------------------------------ *\
   #DASHBOARD-MAIN
\* ------------------------------------ */

.ras-dashboard-main {
    .ras-dashboard-map {
        border-radius: 5px;
        overflow: hidden;
    }
    .ras-dashboard-results-number {
        font-size: $font-size-s;
        padding-bottom: $spacing-unit;
        color: $color-fourth;
        p {
            text-align: center;
        }
    }
}

.ras-dashboard-cards__scroller {
    padding: $spacing-unit;
    overflow-y: auto;
    overflow-x: visible;
}


/* ------------------------------------ *\
   #MEDIA-QUERIES
\* ------------------------------------ */

@media all and (max-width: $breakpoint-ipad-portrait - 1) {
    .ras-dashboard-main {
        max-height: calc(100vh - 67px*2);
        .ras-dashboard-results-number {
            padding-top: $spacing-unit;
        }
        .ras-dashboard-map {
            height: 33vh;
        }
    }
    .ras-dashboard-cards__scroller {
        max-height: calc(66vh - 67px - 67px - 99px);
    }
}

@media all and (min-width: $breakpoint-ipad-portrait) {
    .ras-dashboard-main {
        display: flex;
        justify-content: space-between;
        .is-scrolled & {
            padding-top: 175px;
        }
        .ras-dashboard-map {
            @include flex-item-width(50%);
            height: calc(100vh - #{$header-height} - 170px);
        }
        .ras-dashboard-cards {
            @include flex-item-width(50%);
            padding: $spacing-unit*2 $spacing-unit*2 $spacing-unit;
        }
        .ras-dashboard-results-number {
            padding-bottom: $spacing-unit;
        }
    }
    .ras-dashboard-cards {
        position: relative;
    }
    .ras-dashboard-cards__scroller {
        max-height: calc(100vh - #{$header-height} - 170px - 108px);
    }
}

@media all and (min-width: $breakpoint-ipad-portrait) and (max-width: $breakpoint-laptop-wide - 1) {}

@media all and (min-width: $breakpoint-laptop-wide) {
    .ras-dashboard-main {
        .is-scrolled & {
            padding-top: 98px;
        }
        .ras-dashboard-cards {
            @include flex-item-width(60%);
        }
        .ras-dashboard-results-number {
            padding-bottom: 0;
            text-align: right;
            font-size: $font-size-m;
        }
        .ras-dashboard-map {
            @include flex-item-width(40%);
            height: calc(100vh - #{$header-height} - 83px);
        }
    }
    .ras-dashboard-cards__scroller {
        max-height: calc(100vh - #{$header-height} - 83px - 108px);
    }
    .ras-dashboard-cards__informations {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding-bottom: $spacing-unit*2;
        .ras-dashboard-active-filters__item {
            order: 1;
        }
        .ras-dashboard-results-number {
            order: 2;
            white-space: nowrap;
        }
    }
}