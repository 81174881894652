/**
 * TRACK
 *
 * Style for track details component
 */


/* ------------------------------------ *\
   #TRACK
\* ------------------------------------ */

.ras-track {
    padding: $spacing-unit;
    section:not(.ras-record--expanded) & {
        border-bottom: 1px solid $color-border-normal;
    }
    &:last-of-type {
        border-bottom: none;
    }
    .ras-track__header {
        display: flex;
        align-items: center;
    }
    .ras-track__number {
        padding-right: $spacing-unit;
        font-weight: $font-weight-bold;
    }
    .ras-track__name {
        font-size: $font-size-m;
        font-weight: $font-weight-normal;        
        .fa-exclamation-triangle {
            font-size: 21px;
            line-height: 30px;
            margin-right: 6px;
            margin-bottom: 4px;
            color: #ffa700;
        }
    }
    .ras-track__partial {
        padding: 0 $spacing-unit;
        font-size: 8px;
        text-align: center;
        .ras-track__partial-icon {
            font-size: 12px;
        }
    }
    .ras-track__difficulty {
        padding: 0 $spacing-unit;
    }
    .ras-track__difficulty-scale {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .ras-track__difficulty-label {
        font-weight: $font-weight-medium;
    }
    .ras-track__difficulty-label {
        font-size: 10px;
    }
    &.ras-track--not-covered {
        color: $color-error;
        .ras-track__detail {
            padding-bottom: 0;
        }
        p {
            line-height: 1.2;
        }
    }
    .ras-track__difficulty-item {
        padding-right: $spacing-unit/5;
        font-size: 5px;
        color: $color-background-medium;
        &.is-full {
            color: $color-text;
        }
    }
    .ras-track__actions {
        margin-left: auto;
    }
    .ras-track__detail {
        display: flex;
        align-items: baseline;
        padding-bottom: $spacing-unit;
        font-size: $font-size-xs;
        font-weight: $font-weight-medium;
        [class*="fa"] {
            margin-right: $spacing-unit;
            font-size: $font-size-ml;
        }
    }
    .ras-track__attrattore,
    .ras-track__attrattore-details {
        display: flex;
        align-items: center;
    }
    .ras-track__attrattore {
        padding-top: $spacing-unit;
        .fa-map-marker-alt {
            margin-right: $spacing-unit;
            padding-top: $spacing-unit/2;
        }
        .icon {
            margin-right: $spacing-unit;
            font-size: $font-size-s;
            color: $color-second-light;
        }
    }
    .ras-track__attrattore-details {
        flex: 1;
        padding-top: $spacing-unit/2;
        border-top: 1px solid $color-border-normal;
    }
    .ras-track__tappa-image {
        @include flex-item-width(45px);
        //@include flex-item-width(70px);
        height: 0;
        margin-right: $spacing-unit;
        position: relative;
        padding-bottom: 30px;
        //padding-bottom: 47px;
        overflow: hidden;
        img {
            width: 100%;
            height: auto;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .ras-track__attrattore-image {
        @include flex-item-width(45px);
        //@include flex-item-width(70px);
        height: 0;
        margin-right: $spacing-unit;
        position: relative;
        padding-bottom: 30px;
        //padding-bottom: 47px;
        overflow: hidden;
        margin-left: $spacing-unit*1.5;
        img {
            width: 100%;
            height: auto;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .ras-track__attrattore-name {
        font-size: $font-size-m;
    }
    .ras-track__attrattore-category {
        margin-left: auto;
        font-size: $font-size-xs;
        [class*="fa"] {
            margin-right: $spacing-unit/2;
        }
        &+.ras-track__actions {
            margin-left: 0;
        }
    }
}


/* ------------------------------------ *\
   #MEDIA-QUERIES
\* ------------------------------------ */

@media all and (max-width: $breakpoint-laptop-wide - 1) {}

@media all and (max-width: $breakpoint-ipad-portrait - 1) {
    .ras-track {
        .ras-track__header {
            //flex-flow: row wrap;
        }
    }
}

@media all and (min-width: $breakpoint-ipad-portrait) {
    .ras-track {
        section:not(.ras-record--expanded) & {
            &+.ras-track {
                padding-top: $spacing-unit*2;
            }
        }
        .ras-track__number {
            font-size: $font-size-ml;
        }
        .ras-track__name {
            font-size: $font-size-ml;
        }
        .ras-track__detail {
            font-size: $font-size-s;
        }
        .ras-track__attrattore {
            padding-top: $spacing-unit;
            .fa-map-marker-alt {
                font-size: $font-size-intermedium;
            }
            .icon {
                font-size: $font-size-m;
            }
        }
        .ras-track__attrattore-name {
            //font-size: $font-size-ml;
            font-size: $font-size-m;
        }
    }
}

@media all and (min-width: $breakpoint-ipad-portrait) and (max-width: $breakpoint-laptop-wide - 1) {}

@media all and (min-width: $breakpoint-laptop-wide) {
    .ras-track {
        .ras-track__attrattore-image {
            //@at-root@include flex-item-width(70px);
            //padding-bottom: 47px;
            @include flex-item-width(35px);
            padding-bottom: 35px;
            background-size: cover;
            &.sosta {
                background-image: url(assets/images/sosta-o.svg);
            }
            &.attrattore {
                background-image: url(assets/images/attrattore-o.svg);
            }
            &.servizio {
                background-image: url(assets/images/servizio-o.svg);
            }
        }
        .ras-track__tappa-image {
            @include flex-item-width(50px);
            padding-bottom: 50px;
            background-size: cover;
            background-image: url(assets/images/route.svg);
        }
    }
}

@media all and (min-width: $breakpoint-desktop) {
    .ras-track {}
}