//
// MIXINS
//
// Definition of default project's mixins
//

// ------------------------------------ //
// #INPUT PLACEHOLDER STYLE
// ------------------------------------ //

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}

// ------------------------------------ //
// #RETINA IMAGES MIXIN
// ------------------------------------ //

@mixin image-2x($image, $width, $height) {
    @media (min--moz-device-pixel-ratio: 1.3),
        (-o-min-device-pixel-ratio: 2.6/2),
        (-webkit-min-device-pixel-ratio: 1.3),
        (min-device-pixel-ratio: 1.3),
        (min-resolution: 1.3dppx) {
        /* on retina, use image that's scaled by 2 */
        background-image: url($image);
        background-size: $width $height;
    }
}

// ------------------------------------ //
// #FONT-AWESOME
// ------------------------------------ //

@mixin font-awesome($icon) {
    content: $icon;
    font-family: 'Font Awesome 5 Pro' !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin font-awesome-solid($icon) {
    content: $icon;
    font-family: 'Font Awesome 5 Pro' !important;
    speak: none;
    font-style: normal;
    font-weight: 900;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


@mixin font-awesome-light($icon) {
    content: $icon;
    font-family: 'Font Awesome 5 Pro' !important;
    speak: none;
    font-style: normal;
    font-weight: 300;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin font-awesome-brands($icon) {
    content: $icon;
    font-family: 'Font Awesome 5 Brands' !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin ionicons($icon) {
    content: $icon;
    font-family: 'Ionicons' !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// ------------------------------------ //
// #PX TO REM CALCULATOR
// ------------------------------------ //

@mixin font-size($sizeValue: 12 ) {
    font-size: $sizeValue + px; //fallback for old browsers
    font-size: (0.125 * $sizeValue) + rem;
}

// ------------------------------------ //
// #ANIMATION
// ------------------------------------ //

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

// ------------------------------------ //
// #HIDDEN ELEMENT
// ------------------------------------ //

@mixin hidden-element {
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    z-index: -1;
}

@mixin visible-element($z-index) {
    visibility: visible;
    opacity: 1;
    overflow: visible;
    z-index: $z-index;
}

// ------------------------------------ //
// #WRAPPER
// ------------------------------------ //

//@mixin wrapper {
//    max-width: $breakpoint-laptop;
//    width: 100%;
//    margin-right: auto;
//    margin-left: auto;
//
//    @media all and (max-width: $width-container) {
//        padding: 0 $spacing-unit*2;
//    }
//
//    @media all and (max-width: $breakpoint-ipad-landscape) {
//        padding: 0 $spacing-unit;
//    }
//}

// ------------------------------------ //
// #WORD WRAP
// ------------------------------------ //

@mixin word-wrap() {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

// ------------------------------------ //
// #TEXT ELLIPSIS
// ------------------------------------ //

@mixin ellipsis() {
    margin-right: $spacing-unit*3;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    //position: relative;
    //
    //&::after {
    //    content: '\2026';
    //    position: absolute;
    //    right: -$spacing-unit*3;
    //}
}

@mixin ellipsis-character {
    &::before {
        content: '\2026';
    }
}

// ------------------------------------ //
// #BOX SHADOW
// ------------------------------------ //

@mixin box-shadow {
    box-shadow: 0 0 $spacing-unit transparentize($color-gray-04,0.8);
}

@mixin box-shadow-active {
    box-shadow: 0 0 $spacing-unit/2 2px transparentize($color-fourth,0.5);
}


// ------------------------------------ //
// #HIDE TEXT
// ------------------------------------ //

@mixin hide-text {
    text-indent: -9999px;
    overflow: hidden;
}


// ------------------------------------ //
// #ABSOLUTE POSITION CENTERING
// ------------------------------------ //

@mixin vertical-centering {
    top: 50%;
    transform: translateY(-50%);
}

@mixin horizontal-centering {
    left: 50%;
    transform: translateX(-50%);
}

@mixin position-centering {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}


// ------------------------------------ //
// #LINE HEIGHT CENTERING
// ------------------------------------ //

@mixin line-height-center($size,$width: true) {
    @if $width == true {
        width: $size;
        height: $size;
        line-height: $size;
    }
    @else {
        height: $size;
        line-height: $size;
    }
}

// ------------------------------------ //
// #OVERRIDE CHROME AUTOFILL INPUT BG
// ------------------------------------ //

@mixin override-chrome-autofill-bg($input-height, $input-bg-color) {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 $input-height $input-bg-color inset;
    }
}


// ------------------------------------ //
// #CENTERED CONTAINER
// ------------------------------------ //

@mixin centered-container($width) {
    width: 100%;
    max-width: $width;
    margin-right: auto;
    margin-left: auto;


    @media all and (max-width: $width-container) {
        padding: 0 $spacing-unit*2;
    }

    @media all and (max-width: $breakpoint-ipad-portrait - 1) {
        padding: 0 $spacing-unit;
    }
}

// ------------------------------------ //
// #TRANSITION ALL
// ------------------------------------ //

@mixin transition-all {
    transition: all $transition-durations ease-in;
}

// ------------------------------------ //
// #LINEAR-GRADIENT
// ------------------------------------ //

@mixin linear-gradient($direction,$color1,$color2) {
    background-image: linear-gradient($direction, $color1 0%, $color2 100%);
}

// ------------------------------------ //
// #TEXT-SHADOW
// ------------------------------------ //

@mixin text-shadow {
    text-shadow: 0 0 10px rgba(0,0,0,0.50);
}


// ------------------------------------ //
// #ACCESSIBLE-HIDDEN-TEXT
// ------------------------------------ //

@mixin accessible-text {
    position: absolute !important;
    height: 1px; width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
}

// ------------------------------------ //
// #CLEARFIX
// ------------------------------------ //

@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

// ------------------------------------ //
// #FLEX-ITEM-WIDTH
// ------------------------------------ //

@mixin flex-item-width($width) {
    flex: 0 0 $width;
    width: $width;
}


// ------------------------------------ //
// #MOBILE-FILTERS-PANEL
// ------------------------------------ //

@mixin mobile-filters-panel {
    display: none;
    max-height: 100%;
    padding: $spacing-unit*5.5 $spacing-unit*2 $spacing-unit*2;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $ras-leaflet-components-z-index + 1;
    background: $color-background-light;
    overflow-y: auto;

    &.is-open {
        display: block;
    }

    .ras-btn-action {
        padding: $spacing-unit;
        position: fixed;
        top: $spacing-unit/5;
        right: $spacing-unit*2;
        background: $color-background-light;
        z-index: 1;
        box-shadow: 0 0 5px rgba(0,0,0,0.2);
    }

}


// ------------------------------------ //
// #SCROLL-GRADIENT
// ------------------------------------ //

@mixin scroll-gradient {
    &::after {
        content: '';
        display: block;
        height: $spacing-unit;
        position: absolute;
        right: 0;
        bottom: $spacing-unit*4;
        left: 0px;
        z-index: $ras-modal-z-index;
        background: linear-gradient(to bottom, rgba(255,255,255,0.55) 0%, rgba(246,246,246,0.76) 47%, rgba(237,237,237,1) 100%);

        @media (min-width: $breakpoint-ipad-portrait) {
            bottom: $spacing-unit*6;
        }
    }

}