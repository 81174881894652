/**
 * MODAL-COMPONENTS
 *
 * Style for dialog and inner components
 */


/* ------------------------------------ *\
   #MODAL-COMPONENTS
\* ------------------------------------ */


/* Temporary */

.ras-difficulty-scale {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    .ras-difficulty-scale__item {
        padding-right: $spacing-unit/5;
        font-size: 5px;
        color: $color-background-medium;
        &.is-full {
            color: $color-text;
        }
    }
}


/* Modal */

mat-dialog-container.mat-dialog-container {
    color: $color-text;
    max-height: 95vh;
}

.ras-modal {
    .ras-modal__content {
        padding: $spacing-unit * 2;
    }
    .ras-modal__header {
        margin-bottom: $spacing-unit;
        padding: $spacing-unit 0;
        border-bottom: 1px solid $color-border-normal;
    }
    .ras-modal__body {
        padding: $spacing-unit 0;
    }
    .ras-modal__title {
        font-weight: $font-weight-normal;
        width: 100%;
        margin-bottom: 0;
        [class*="fa"] {
            margin-right: $spacing-unit;
            color: $color-second-light;
        }
    }
    .ras-difficulty-scale {
        margin-right: $spacing-unit/2;
        .ras-difficulty-scale__item {
            font-size: $font-size-s;
        }
    }
    .ras-modal__options {
        display: flex;
        //display: inline-flex;
        .ras-card__feature {
            margin-right: 12px;
            font-size: 13px;
            i {
                color: rgba(56, 56, 56, 0.4);
                margin-right: 6px;
            }
        }
    }
    .ras-modal__options-item {
        padding: $spacing-unit;
        &:last-of-type {
            border-right: none;
        }
    }
    .ras-modal__options-item-label {
        font-size: $font-size-xxs;
        font-style: italic;
    }
    .ras-modal__options-item-value {
        display: flex;
        align-items: center;
        font-size: $font-size-m;
    }
    .ras-modal__details-wrapper {
        margin-bottom: $spacing-unit;
        padding-bottom: $spacing-unit;
        border-bottom: 1px solid $color-border-normal;
    }
    .ras-modal__detail {
        display: flex;
        font-size: $font-size-s;
        [class*="fa"] {
            margin-right: $spacing-unit;
        }
    }
    .ras-image-toolbox {
        display: block;
        position: absolute;
        bottom: 0px;
        width: 100%;
        background: rgba(22, 73, 123, 0.3);
        border-radius: 2px;
        .ras-image-tool {
            width: 50%;
            display: inline-block;
            text-align: center;
            cursor: pointer;
            opacity: 0.4;
            &:hover {
                opacity: 1;
            }
        }
    }
    .ras-image-thumb {
        width: 100px;
        height: 100px;
        overflow: hidden;
        position: relative;
        margin-bottom: $spacing-unit;
        cursor: pointer;
        // border: 2px $color-button-bg solid;
        border-radius: 3px;
        background: rgba(22, 73, 123, 0.1);
        // &.ras-file-thumb{
        // }
        .ras-intense-file {
            i {
                font-size: 98px;
                text-align: center;
                width: 100%;
                opacity: 0.1;
            }
            .ras-intense-filename {
                text-align: center;
                font-size: 0.9em;
                top: 50%;
                position: absolute;
                transform: translateY(-50%);
            }
        }
        img {
            width: 100%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 0;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
        }
        .ras-form-btn-img-remove {
            display: block;
            position: absolute;
            background: $color-second-light;
            color: #fff;
            opacity: 0.01;
            padding-top: 7px;
            bottom: 0px;
            width: 100%;
            &:hover {
                opacity: 0.9;
            }
            i {
                height: 2 * $spacing-unit;
                vertical-align: middle;
            }
            .img-remove-cmd {
                display: inline-block;
                width: 50%;
            }
        }
        .ras-form-btn-img-zoom {
            display: block;
            position: absolute;
            bottom: 3px;
            right: 3px;
            background: transparent;
            color: #fff;
            opacity: 0.91;
            text-shadow: 1px 1px 3px #000;
            &:hover {
                opacity: 1;
                font-size: 1.1em;
            }
        }
    }
    .ras-errormsg {
        color: #f00;
    }
    .ras-form-item__image-upload {
        display: flex;
        flex-flow: row wrap;
        >.ras-form-item__label {
            width: 100%;
        }
        .ras-form-item {
            @include flex-item-width(100%);
        }
        .ras-form-item__file.ras-form-item__file-inline {
            width: inherit;
            flex: none;
        }
    }
    .ras-btn-light {
        font-size: $font-size-s;
    }
    .ras-modal__message {
        font-size: $font-size-intermedium;
    }
}

.ras-component-column {
    .ras-form-item {
        border-top: 1px solid $color-border-normal;
    }
}

.ras-component-column-title {
    margin-bottom: $spacing-unit;
}

.ras-snackbar-line {
    i {
        padding-left: $spacing-unit;
        &.hide {
            display: none;
        }
    }
}


/* Modal actions */

.ras-modal__actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.ras-modal-translate {
    .rowlang {
        display: flex;
        justify-content: space-between;
        background-color: #f4f4f4;
        padding: 14px;
        margin: 12px 0;
        &.title-lang {
            background-color: $color-second;
            color: #fff;
        }
        .origin {
            padding-right: 12px;
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 50%;
            border-right: 1px solid #e0e7ff;
        }
        .translate {
            padding-left: 12px;
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 50%;
        }
    }
    .ras-list-ost {
        //padding: 30px 0;
        .ras-list-ost-item {
            //border-bottom: $color-gray-03 solid 1px;
            min-height: 40px;
            border-radius: 3px;
            //margin-bottom: 12px;
            font-size: $font-size-body;
            font-weight: $font-weight-bold;
            i {
                float: right;
            }
        }
    }
}


/* ------------------------------------ *\
   #MEDIA-QUERIES
\* ------------------------------------ */

@media all and (max-width: $breakpoint-ipad-portrait - 1) {
    .ras-modal {
        .ras-modal__options {
            justify-content: space-between;
        }
        .ras-modal__title {
            text-align: center;
            //width: 100%;
        }
        .ras-btn-light {
            flex: 1;
            width: auto;
        }
    }
    .ras-component-column {
        margin-bottom: $spacing-unit * 2.5;
    }
}

@media all and (min-width: $breakpoint-ipad-portrait) {
    .ras-modal {
        .ras-modal__header {
            display: flex;
            align-items: center;
            padding: $spacing-unit * 2 0;
            flex-wrap: wrap;
        }
        .ras-modal__body {
            padding: $spacing-unit * 2 0;
        }
        .ras-modal__options {
            //margin-left: auto;
        }
        .ras-modal__options-item {
            border-right: 1px solid $color-border-normal;
        }
        .ras-modal__options-item-label {
            font-size: $font-size-xxs;
        }
        .ras-modal__options-item-value {
            font-size: $font-size-intermedium;
        }
        .ras-modal__details-wrapper {
            margin-bottom: $spacing-unit * 2;
            padding-bottom: $spacing-unit * 2;
        }
        .ras-modal__add-content {}
        form {
            display: flex;
        }
        .ras-form-item__textarea {
            padding-right: $spacing-unit * 4;
            @include flex-item-width(75%);
        }
        .ras-btn-light {
            flex: 1;
            min-width: initial;
        }
        .ras-image-thumb {
            margin-right: $spacing-unit;
        }
        .ras-modal__message {
            font-size: $font-size-l;
        }
        &.ras-modal--has-columns {
            .ras-modal__body {
                display: flex;
            }
        }
        .ras-component-column {
            @include flex-item-width(48%);
            margin-right: 4%;
            &:nth-of-type(2n + 2) {
                margin-right: 0;
            }
        }
    }
    .ras-modal__actions {
        &.ras-modal__actions-justified {
            justify-content: space-between;
        }
    }
}

@media all and (min-width: $breakpoint-ipad-portrait) and (max-width: $breakpoint-laptop-wide - 1) {}

@media all and (min-width: $breakpoint-laptop-wide) {}