/**
 * RECORD
 *
 * Style for record layout
 */

/* ------------------------------------ *\
   #RECORD
\* ------------------------------------ */

.app-step-zero {
    height: 100%;
}

/* ------------------------------------ *\
   #MEDIA-QUERIES
\* ------------------------------------ */

@media all and (max-width: $breakpoint-ipad-portrait - 1) {
    .ras-record {
        .ras-record-map__scroller {
            padding-bottom: $spacing-unit;
        }
    }
}

@media all and (min-width: $breakpoint-ipad-portrait) {
    .ras-record {
        display: flex;
        height: 100%;
        align-items: stretch;

        .ras-record-sidebar {
            @include flex-item-width(25%);
        }

        .ras-record-map {
            flex: 1;
            //max-width: 80%;
        }

        .ras-record-map__main {
            display: flex;
        }

        .ras-record-map__details {
            @include  flex-item-width(50%);
        }

        .ras-record-map__scroller {
            overflow-y: auto;
            overflow-x: visible;
        }

        &.has-sidebar-closed {
            .ras-btn-action--has-label {
                display: none;
            }

            .ras-record-sidebar {
                @include  flex-item-width(90px);
            }
        }

        &.has-sidebar-hidden {
            .ras-record-sidebar {
                @include  flex-item-width(0);
                position: absolute;
                left: -9999px;
                opacity: 0;
            }
        }

        &.has-filters-collapsed {
            .ras-record-sidebar {
                height: $header-record-height;
                overflow: hidden;
                position: absolute;
                top: 0;
                left: 0;
            }

            .ras-record-map__header {
                padding-left: calc(20% + #{$spacing-unit});
            }
        }

        &.ras-record--add-track {
            .ras-record-map__scroller {
                flex: 0 0 calc(100vh - 80px - 60px - 74px);
                max-height: calc(100vh - 80px - 60px - 74px);
            }
        }

        &.ras-record--expanded {
            .ras-record-map__map {
                @include  flex-item-width(40%);
            }

            .ras-record-map__details {
                @include  flex-item-width(60%);
                margin-top: -$header-record-height;
                border-left: 1px solid $color-border-normal;
            }

            .ras-record-map__scroller {
                flex: 0 0 calc(100vh - 80px);
                max-height: calc(100vh - 80px);
            }
        }
    }
}

@media all and (min-width: $breakpoint-ipad-portrait) and (max-width: $breakpoint-laptop-wide - 1) {

}

@media all and (min-width: $breakpoint-laptop-wide) {

    .ras-record {
        .ras-record-map__details {
            @include  flex-item-width(33.333%);
        }

        .ras-record-sidebar {
            @include flex-item-width(20%);
        }

        //.ras-record-map {
        //    .ras-record-map__details {
        //        @include  flex-item-width(25%);
        //    }
        //
        //}
    }
}
