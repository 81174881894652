/**
 * FORMS
 *
 * Style for forms elements
 */


/* ------------------------------------ *\
   #GENERAL
\* ------------------------------------ */

select,
textarea,
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"] {
    height: $form-input-height;
    padding: 0 $spacing-unit;
    border-radius: 2px;
    background: #ffffff;
    border: 1px solid $color-border-normal;
    color: $color-text;
    font-size: $font-size-s;
    font-weight: $font-weight-normal;
    line-height: $form-input-height;
    box-shadow: none;
    outline: none;
    transition-property: border;
    transition-duration: $transition-durations;
    .ras-form-item & {
        height: $form-input-height;
        padding: 0 $spacing-unit*2;
        font-size: $font-size-ml;
        line-height: $form-input-height;
        @include placeholder {
            font-weight: $font-weight-normal;
            font-size: $font-size-ml;
        }
    }
    &:focus,
    &:active {
        border-color: $color-main;
        box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.05), 0 0 2px fade($color-second, 20%);
    }
    @include placeholder {
        color: $color-text-form-placeholder;
    }
}

.ras-form-item {
    textarea {
        line-height: 1.5rem;
        padding: 12px;
    }
}


/* Search bar */

.ras-search-bar {
    padding-right: $spacing-unit*3;
    background: #ffffff;
    border: 1px solid $color-border-normal;
    position: relative;
    input[type="text"] {
        width: 100%;
        border: none;
        background: transparent;
        position: relative;
        z-index: 1;
        @include placeholder {
            color: $color-text-dark;
        }
    }
    [class*="fa"] {
        position: absolute;
        right: $spacing-unit;
        z-index: 0;
        @include vertical-centering;
    }
}


/* Autocomplete */

.ras-search-autocomplete {
    .mat-form-field-infix,
    .mat-form-field {
        width: 100%;
    }
    .mat-form-field-wrapper {
        padding-bottom: 0;
    }
    .mat-form-field-infix {
        padding: 0;
        border-top: 0;
    }
    .mat-form-field-label {
        padding-top: $spacing-unit*1.2;
        left: $spacing-unit;
        color: $color-text;
        font-size: $font-size-s;
    }
    .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
        top: $spacing-unit*3;
        color: $color-main;
    }
    .mat-form-field-appearance-legacy {
        .mat-form-field-wrapper {
            padding-bottom: 0;
        }
        .mat-form-field-infix {
            padding: 0;
            border-top: 0;
        }
        .mat-form-field-underline {
            display: none;
        }
    }
    .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
        width: 100%;
        padding-top: $spacing-unit/2;
    }
}


/* Autocomplete overrides */

.mat-autocomplete-panel {
    .mat-option {
        padding: 0 $spacing-unit;
        font-family: $font-family-main;
        font-size: $font-size-m;
        .fa-route {
            margin-right: $spacing-unit*2;
        }
    }
    .mat-option-text {
        display: flex;
        align-items: center;
        width: 100%;
    }
    .ras-search-autocomplete__add {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: $spacing-unit;
        margin-left: auto;
        font-size: $font-size-intermedium;
    }
    .ras-search-autocomplete__footer {
        border-top: 1px solid $color-border-normal;
    }
    .ras-search-autocomplete__footer-btn {
        display: flex;
        align-items: center;
        padding: $spacing-unit;
        font-size: $font-size-xs;
        [class*="fa"] {
            margin-right: $spacing-unit*2;
        }
    }
    .ras-search-autocomplete__others {
        margin-top: 0;
        margin-right: -$spacing-unit;
        margin-bottom: 0;
        margin-left: -$spacing-unit;
        padding-right: $spacing-unit;
        padding-left: $spacing-unit;
        list-style: none;
        border-top: 2px solid $color-border-normal;
    }
    .ras-search-autocomplete__others-item {
        padding: $spacing-unit $spacing-unit*2;
        cursor: pointer;
        border-bottom: 1px solid $color-border-normal;
        color: $color-text-light;
        //transition: all $transition-durations ease-in;
        &:hover,
        &:focus {
            background: rgba(0, 0, 0, .04);
        }
        [class*="fa"] {
            margin-right: $spacing-unit*2;
        }
        &:last-of-type {
            border-bottom: none;
        }
    }
}

.ras-search-bar {
    @include flex-item-width(100%);
}

.mat-autocomplete-panel {
    padding: $spacing-unit;
    border-top: 2px solid $color-text;
}

.mat-option {
    padding-right: 0;
    padding-left: 0;
    border-bottom: 1px solid $color-border-normal;
    font-size: $font-size-s;
    color: $color-text-light;
    &:last-of-type {
        border-bottom: 0;
    }
}


/* Form item */

.ras-form-item {
    padding-bottom: $spacing-unit;
    &.disable {
        opacity: 0.2;
        cursor: no-drop;
        .ras-form-item__legend {
            cursor: no-drop
        }
    }
    select,
    textarea,
    input[type="date"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="text"],
    input[type="time"],
    input[type="url"],
    input[type="week"],
    .mat-form-field {
        width: 100%;
    }
    textarea {
        height: 160px;
    }
    .ras-dashboard-filters & {
        border-bottom: 1px solid $color-border-light;
    }
    .ras-form-item__legend {
        display: flex;
        align-items: center;
        width: 100%;
        [class*="fa"] {
            margin-left: auto;
            font-size: $font-size-s;
            float: right;
            line-height: 22px;
        }
        .fa-chevron-up {
            display: none;
        }
    }
    .ras-form-item__label {
        display: block;
        margin-bottom: $spacing-unit/2;
        font-weight: $font-weight-bold;
    }
    .ras-start & {
        .ras-form-item__label {
            font-size: $font-size-ml;
        }
    }
    &.ras-form-item--select {
        position: relative;
        .ras-form-item__legend {
            cursor: pointer;
        }
    }
    .ras-form-item__options {
        display: none;
        width: 100%;
        padding: $spacing-unit;
        position: absolute;
        z-index: $ras-leaflet-components-z-index + 1;
        /* to override leaftlet z-index: 400 */
        background: #ffffff;
        border-top: 2px solid $color-text;
        @include box-shadow;
        .ras-checkbox {
            border-bottom: 2px solid $color-border-normal;
            &:last-of-type {
                border-bottom: none;
            }
        }
    }
    &.is-open {
        .ras-form-item__options {
            display: block;
        }
        .ras-form-item__legend {
            font-weight: $font-weight-bold;
            .fa-chevron-up {
                display: inline-block;
            }
            .fa-chevron-down {
                display: none;
            }
        }
    }
    &.ras-form-item__file {
        input[type="file"] {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
            &+label {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                flex-flow: column nowrap;
                width: 100px;
                height: 100px;
                background: $color-button-bg;
                color: #ffffff;
                border-radius: 3px;
                font-weight: $font-weight-bold;
                cursor: pointer;
                text-align: center;
                text-transform: uppercase;
                font-size: $font-size-s;
                [class*="fa"] {
                    margin-bottom: $spacing-unit;
                }
            }
            &:focus+label,
            &+label:hover {
                background-color: $color-button-bg-hover;
            }
        }
    }
    /* Slider */
    &.ras-form-item--slider {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
        .ras-form-item__label {
            color: $color-text;
            font-size: $font-size-s;
        }
    }
    .ras-form-item--slider__range {
        display: flex;
        width: 100%;
        margin-bottom: -11px;
        .ras-form-item--slider__max {
            margin-left: auto;
        }
    }
    .mat-slider {
        padding: 0;
    }
    .mat-slider-horizontal {
        width: 100%;
        height: 2px;
        position: relative;
        &::before,
        &::after {
            content: '';
            display: block;
            width: 2px;
            height: 7px;
            background: $color-background-medium;
            position: absolute;
            bottom: -11px;
            //@include vertical-centering;
        }
        &::before {
            left: 0;
        }
        &::after {
            right: 0;
        }
        .mat-slider-wrapper {
            top: 9px;
            right: 1px;
            left: 1px;
        }
        .mat-slider-ticks-container {}
    }
    .mat-slider-thumb,
    .mat-slider-track-fill {
        background: $color-main;
    }
    .mat-slider-thumb-label {
        color: $color-main;
    }
    .mat-slider-track-background {
        background: $color-background-medium;
    }
    &.ras-form-item--action {
        padding: $spacing-unit 0;
        .ras-btn {
            width: 100%;
        }
    }
    &.ras-form-item--small {
        select,
        textarea,
        input[type="date"],
        input[type="datetime"],
        input[type="datetime-local"],
        input[type="email"],
        input[type="month"],
        input[type="number"],
        input[type="password"],
        input[type="search"],
        input[type="tel"],
        input[type="text"],
        input[type="time"],
        input[type="url"],
        input[type="week"],
        .mat-form-field {
            height: $form-input-small-height;
            line-height: $form-input-small-height;
        }
    }
}


/* Material Angular Library */

.mat-form-field {
    font-family: $font-family-main;
}

.mat-form-field-label {
    .mat-form-field.mat-focused & {
        color: $color-main;
    }
}

.mat-form-field-ripple {
    .mat-form-field.mat-focused & {
        background-color: $color-main;
    }
}


/* Datepicker */

.ras-datepicker {
    padding: $spacing-unit;
    position: relative;
    input[type="text"],
    input[type="date"] {
        height: $form-input-medium-height;
        padding: $spacing-unit/2;
        background: transparent;
        border-top: none !important;
        border-right: none !important;
        border-left: none !important;
        border-bottom-color: $color-border-light;
        border-radius: 0;
        line-height: $form-input-medium-height;
    }
    .mat-button-wrapper {
        &::before {
            @include font-awesome('\f078');
            font-size: $font-size-xs;
        }
        .mat-datepicker-toggle-default-icon {
            display: none;
        }
    }
    [class*="fa"] {
        //position: absolute;
        //right: $spacing-unit;
        //bottom: $spacing-unit*2;
        font-size: $font-size-xs;
    }
    .ras-form-item__label {
        font-size: $font-size-s;
    }
    .ras-start & {
        .ras-form-item__label {
            margin-bottom: $spacing-unit/2;
            font-size: $font-size-s;
        }
    }
    //@include placeholder,
}


/* Radio & checkbox */

.ras-checkbox,
.ras-radio {
    display: flex;
    align-items: center;
    padding: $spacing-unit;
    color: $color-text-dark;
    position: relative;
    label {
        font-size: $font-size-m;
        color: $color-text;
        font-weight: $font-weight-medium;
    }
    input[type="checkbox"],
    input[type="radio"] {
        width: 0;
        height: 0;
        position: absolute;
        opacity: 0;
        cursor: pointer;
        &+label {
            display: inline-flex;
            align-items: center;
            //padding-left: $spacing-unit*2;
            &:before {
                color: $color-border-light;
                font-size: $font-size-m;
            }
        }
    }
}


/* Radio */

.ras-radio {
    input[type="radio"] {
        &+label {
            //color: $color-second-light;
            &:before {
                margin-right: $spacing-unit/2;
                @include font-awesome('\f111');
            }
        }
        &:checked {
            &+label {
                color: $color-text;
                &:before {
                    @include font-awesome('\f192');
                    color: $color-text;
                }
            }
        }
    }
}


/* Checkboxes */

.ras-checkbox {
    input[type="checkbox"] {
        &+label {
            &:before {
                margin-right: $spacing-unit*1.5;
                @include font-awesome-light('\f0c8');
                font-size: $font-size-m;
            }
        }
        &:checked {
            &+label {
                &:after {
                    position: absolute;
                    left: $spacing-unit + 1;
                    @include font-awesome-solid('\f00c');
                    font-size: $font-size-xs;
                }
            }
        }
    }
}

fieldset {
    margin: 0;
    padding: 0;
    border: none;
}

.ras-form-item__wrapper {
    &+& {
        padding-top: $spacing-unit*2;
    }
    &.ras-form-item__wrapper-hidden {
        position: absolute;
        transition: all $transition-durations ease-in;
        @include hidden-element;
        &.is-visible {
            position: relative;
            @include visible-element(auto);
        }
    }
}


/* ------------------------------------ *\
   #MEDIA-QUERIES
\* ------------------------------------ */

@media all and (max-width: $breakpoint-ipad-portrait - 1) {
    /* Set Height */
    select,
    textarea,
    input[type="date"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="text"],
    input[type="time"],
    input[type="url"],
    input[type="week"] {
        .ras-form-item & {
            padding: 0 $spacing-unit;
            font-size: $font-size-m;
            line-height: $form-input-medium-height;
            @include placeholder {
                font-size: $font-size-m;
            }
        }
    }
    select,
    input[type="date"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="text"],
    input[type="time"],
    input[type="url"],
    input[type="week"] {
        .ras-form-item & {
            height: $form-input-medium-height;
        }
    }
    textarea {
        height: 100px;
    }
    .ras-checkbox,
    .ras-radio {
        padding-right: $spacing-unit*2;
    }
    .ras-search-autocomplete {
        input[type="text"] {
            height: $form-input-medium-height;
        }
    }
}

@media all and (max-width: $breakpoint-smartphone-landscape) {
    //.ras-form-item {
    //    .ras-form-item__options {
    //
    //    }
    //}
}

@media all and (min-width: $breakpoint-ipad-portrait) {
    .ras-form-item {
        .ras-start & {
            .ras-form-item__label {
                margin-bottom: $spacing-unit;
                font-size: $font-size-l;
            }
        }
        &.ras-form-item--inline {
            display: flex;
            align-items: baseline;
            >.ras-form-item__label {
                @include flex-item-width(30%);
                margin-bottom: 0;
            }
            .ras-form-item__wrapper {
                //width: 100%;
                .ras-form-item__wrapper {
                    display: flex;
                }
                .ras-form-item {
                    margin-right: $spacing-unit*2;
                }
            }
        }
        .ras-form-item__options {
            width: 350px;
        }
    }
    .ras-checkbox,
    .ras-radio {
        padding: $spacing-unit*1.5 $spacing-unit;
        label {
            font-size: $font-size-intermedium;
        }
    }
    .ras-datepicker {
        .ras-form-item__label {
            font-size: $font-size-s;
        }
        .ras-start & {
            .ras-form-item__label {
                margin-bottom: $spacing-unit/2;
                font-size: $font-size-s;
            }
        }
    }
    .ras-search-autocomplete {
        .mat-form-field-label {
            padding-top: 8px;
            font-size: $font-size-m;
        }
        .ras-dashboard-filters & {
            .mat-form-field-label {
                padding-top: $spacing-unit*1.3;
                font-size: $font-size-m;
            }
        }
    }
    .mat-autocomplete-panel {
        .mat-option {
            //padding: 0 $spacing-unit*2;
            font-size: $font-size-intermedium;
        }
        .ras-search-autocomplete__add {
            font-size: $font-size-l;
        }
        .ras-search-autocomplete__footer-btn {
            padding: $spacing-unit $spacing-unit*2;
            font-size: $font-size-s;
        }
    }
}

@media all and (min-width: $breakpoint-smartphone-landscape + 1) {}