/**
 * FROALA-EDITOR
 *
 * Froala Text Editor Customizations
 */


/* ------------------------------------ *\
   #FROALA-EDITOR
\* ------------------------------------ */

.fr-toolbar {
    .ras-form-item & {
        border-top: 1px solid $color-border-normal;
        border-right: 1px solid $color-border-normal;
        border-left: 1px solid $color-border-normal;
        &.fr-top {
            box-shadow: none;
            border-bottom: 1px solid $color-border-normal;
        }
        .fr-command {
            &.fr-btn {
                color: $color-text;
            }
        }
    }
}

.fr-box {
    .ras-form-item & {
        &.fr-basic {
            .fr-wrapper {
                border-right: 1px solid $color-border-normal;
                border-bottom: 1px solid $color-border-normal;
                border-left: 1px solid $color-border-normal;
            }
            &.fr-top {
                .fr-wrapper {
                    box-shadow: none;
                }
            }
        }
    }
}


/* ------------------------------------ *\
   #QL-TOOLBAR
\* ------------------------------------ */

.ql-toolbar {
    .ras-form-item & {
        &.ql-snow {
            .ql-stroke {
                stroke: $color-text;
            }
            .ql-fill {
                fill: $color-text;
            }
            .ql-picker {
                color: $color-text;
            }
        }
    }
}

.ql-toolbar,
.ql-container {
    .ras-form-item & {
        &.ql-snow {
            border: 1px solid $color-border-normal;
        }
    }
}

.ql-container {
    .ras-form-item & {
        height: 200px;
        //height: 120px;
    }
}

.ql-editor {
    .ras-form-item & {
        p,
        ol,
        ul,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        blockquote,
        pre {
            color: $color-text-light;
            font-size: $font-size-s;
            //font-weight: $font-weight-bold; /// GIBIN ///
        }
    }
}


/* ------------------------------------ *\
   #MEDIA-QUERIES
\* ------------------------------------ */

@media all and (max-width: $breakpoint-laptop-wide - 1) {}

@media all and (max-width: $breakpoint-ipad-portrait - 1) {}

@media all and (min-width: $breakpoint-ipad-portrait) {}

@media all and (min-width: $breakpoint-ipad-portrait) and (max-width: $breakpoint-laptop-wide - 1) {}

@media all and (min-width: $breakpoint-laptop-wide) {}

@media all and (min-width: $breakpoint-desktop) {}