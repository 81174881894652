//
// VARIABLES
//
// Definition of default project variables
//
// ------------------------------------ //
// #FONTS
// ------------------------------------ //
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,600,700');
$font-family-main: 'Nunito Sans',
sans-serif;
//$font-weight-extra-light: 200;
//$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 600;
$font-weight-bold: 700;
$font-size-body: 16px;
$font-size-xxs: 0.6875rem; // (11px)
$font-size-xs: 0.75rem; // (12px)
$font-size-s: 0.875rem; // (14px)
$font-size-m: 1rem; // (16px)
$font-size-intermedium: 1.125rem; // (18px)
$font-size-ml: 1.25rem; // (20px)
$font-size-l: 1.375rem; // (22px)
$font-size-ll: 1.625rem; // (26px)
$font-size-2m: 2rem; // (32px)
$font-size-xl: 2.25rem; // (36px)
$font-size-xxl: 2.5rem; // (40px)
$font-size-xxxl: 2.75rem; // (44px)
$font-size-extra: 3.125rem; //(50px)
// ------------------------------------ //
// #SPACING-UNIT
// ------------------------------------ //
$spacing-unit: 10px;
// ------------------------------------ //
// #LAYOUT
// ------------------------------------ //
$ui-width: 1440px;
$width-container: 1440px;
$full-container: 100%; /// GIBIN ////
// ------------------------------------ //
// #MEDIA-QUERIES-BREAKPOINT
// ------------------------------------ //
$breakpoint-desktop: 1220px;
$breakpoint-laptop-wide: 1029px;
$breakpoint-laptop: 980px;
$breakpoint-ipad-portrait: 768px;
$breakpoint-boostrap-sm: 576px;
$breakpoint-smartphone-landscape: 480px;
// ------------------------------------ //
// #COLOR-SCHEME
// ------------------------------------ //
$color-gray-00: #BFC5D2; //sfondo scuro
$color-gray-01: #f9f9f9; // bg light
$color-gray-02: #e0e7ff; //color border
$color-gray-03: #a2aeba; //color border filters (light)
$color-gray-04: #596F86; // color text light
$color-gray-05: #2E384D;
$body-bg: #F5F5F5;
$color-main: #2e5bff;
$color-second: #16497B; //color-text
$color-third: #4F6E17;
$color-fourth: #D9531F;
$color-error: #D0021B;
$color-second-light: transparentize($color-second,
0.4);
$color-card-icons: transparentize(#383838,
0.6);
// ------------------------------------ //
// #COLORS
// ------------------------------------ //
// Text colors
$color-text: $color-second;
$color-text-dark: $color-gray-05;
$color-text-light: $color-gray-04;
$color-text-link: $color-main;
$color-text-hover: darken($color-main,
15%);

/* Used for text link in hover state */

$color-text-link-hover: $color-text-hover;
$color-text-form-placeholder: $color-gray-00;
$color-text-label: $color-text-light;
// Borders
$color-border-light: $color-gray-03;
$color-border-normal: $color-gray-02;
// Backgrounds
$color-background-light: $color-gray-01;
$color-background-medium: $color-gray-03;
$color-background-dark: $color-gray-05;
// ------------------------------------ //
// #BUTTONS
// ------------------------------------ //
$button-border-width: 186px;
$button-normal-height: 40px;
$button-large-height: 56px;
$button-l-height: 60px;
$button-l-height-mobile: 45px;
// Normal button
$color-button-text: $color-text-link;
$color-button-text-hover: #ffffff;
$color-button-text-disabled: rgba($color-button-text,
.4);
$color-button-bg: $color-main;
$color-button-bg-hover: darken($color-main,
10%);
$color-button-border: $color-border-normal;
$color-button-border-hover: $color-gray-03;
// Secondary button
$color-button-secondary-text: $color-gray-03;
$color-button-secondary-text-hover: $color-gray-03;
$color-button-secondary-text-disabled: rgba($color-gray-03,
.4);
$color-button-secondary-bg: $color-gray-03;
$color-button-secondary-bg-hover: darken($color-gray-03,
10);
$color-button-secondary-bg-active: darken($color-gray-03,
10);
$color-button-secondary-bg-disabled: rgba($color-gray-03,
.9);
$color-button-secondary-border: darken($color-gray-03,
10);
// Light button (no background or border, only text)
$color-button-light-text: $color-main;
$color-button-light-text-hover: $color-text-link-hover;
$color-button-light-text-disabled: rgba($color-button-light-text,
.4);
$color-button-light-text-active: $color-text-link-hover;
// Call to action button
$color-button-cta-text: $color-main;
$color-button-cta-text-hover: $color-main;
$color-button-cta-text-disabled: rgba($color-main,
.4);
$color-button-cta-bg: $color-main;
$color-button-cta-bg-hover: darken($color-main,
10%);
$color-button-cta-bg-active: lighten($color-main,
10%);
$color-button-cta-bg-disabled: rgba($color-main,
.9);
$color-button-cta-border: darken($color-main,
10); // $color-text-hover;
// ------------------------------------ //
// #TRANSITIONS
// ------------------------------------ //
$transition-durations: 0.35s;
// ------------------------------------ //
// #IMAGE-PATH
// ------------------------------------ //
$image-path: "src/images/";
// ------------------------------------ //
// #HEADER
// ------------------------------------ //
$header-height: 83px;
$header-record-height: 60px;
$header-record-height-mobile: 45px;
// ------------------------------------ //
// #FORMS
// ------------------------------------ //
$form-input-height: $button-large-height;
$form-input-medium-height: 45px;
$form-input-small-height: $button-normal-height;
// ------------------------------------ //
// #Z-INDEX
// ------------------------------------ //
$zindex-base: 2147483627; // Max allowd value = 2147483647
$zindex-ras-base: 10; // Max allowd value = 2147483647
$ras-navbar-z-index: 10+$zindex-ras-base;
$ras-simple-search-z-index: 20+$zindex-ras-base;
$ras-dropdown-z-index: 30+$zindex-ras-base;
$ras-autocomplete-z-index: 35+$zindex-ras-base;
$ras-modal-z-index: 40+$zindex-ras-base;
$ras-leaflet-z-index: 400;
$ras-leaflet-components-z-index: 1000;