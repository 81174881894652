/**
 * LEAFLET
 *
 * Style for leaflet map
 */

/* ------------------------------------ *\
   #LEAFLET
\* ------------------------------------ */

.leaflet-container {
    height: 100%;

    &.ras-record-map {

    }
}


/* ------------------------------------ *\
   #MEDIA-QUERIES
\* ------------------------------------ */

@media all and (max-width: $breakpoint-ipad-portrait - 1) {
}

@media all and (min-width: $breakpoint-ipad-portrait) {
    .leaflet-container {
        height: 100%;

        //.ras-dashboard-main & {
        //    height: calc(100vh - #{$header-height} - 170px);
        //}

        //.ras-record-map & {
        //    height: calc(100vh - #{$header-record-height} - 1px);
        //}
    }

}


@media all and (min-width: $breakpoint-ipad-portrait) and (max-width: $breakpoint-laptop-wide - 1) {
}

@media all and (min-width: $breakpoint-laptop-wide) {
    .leaflet-container {

        //.ras-dashboard-main & {
        //    height: calc(100vh - #{$header-height} - 83px);
        //}

        //.ras-record-map & {
        //    height: calc(100vh - #{$header-record-height} - 1px);
        //}
    }
}
