/**
 * LAYOUT
 *
 * Layout styles
 */


/* ------------------------------------ *\
   #LAYOUT
\* ------------------------------------ */

.ras-container {
    @include centered-container($width-container);
}

.ras-container-medium {
    @include centered-container(1200px);
}


/* Centered wrapper - es for login */

.ras-wrapper-centered {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.ras-wrapper-centered-login {}


/* ------------------------------------ *\
   #GIBIN
\* ------------------------------------ */

.leaflet-pane>svg path.leaflet-interactive {
    /* stroke: #fff;
    fill-opacity: 0.8;
    stroke-width: 1px; */
}

.ras-form-item {
    .ql-editor {
        background-color: #ffffff;
        p {
            font-weight: 500;
        }
    }
}

.ras-record-facets .mat-expansion-panel-header[aria-disabled="true"] span {
    opacity: 0.7;
}

.btnaddremove {
    float: right;
}

.ras-form-item__period {
    border-bottom: 1px solid #e0e7ff;
    margin-bottom: 20px;
    min-height: 83px;
    select {
        &.ng-pristine {
            min-width: 200px !important;
            margin-right: 20px;
        }
        &.ng-dirty {
            min-width: 200px !important;
            margin-right: 20px;
        }
    }
    .date-inizio-fine {
        display: inline-block;
        .mat-form-field {
            margin: 0 0 0 12px;
            .mat-form-field-underline {
                left: 0;
            }
        }
    }
    .ras-btn {
        &.btn-addremove-date {
            display: inline-block;
            height: 60px;
            width: 60px;
            margin-left: 12px;
            min-width: inherit;
            i {
                font-size: 21px;
                margin: 0;
            }
        }
    }
    .ng-star-inserted {
        display: inline-table;
        //padding-left: 30px;
    }
    .fullyear {
        display: inline-block;
        padding: 0 20px;
    }
}

.ras-dashboard-filters {
    .ras-dashboard-filters__row {
        &.ras-dashboard-filters__row-others {
            &.is-open {
                flex: 0 0 100%;
                border-left: none;
            }
        }
    }
}

.ras-action-wrapper {
    height: 56px;
    .status {
        height: 40px;
        display: inline-flex;
        overflow: hidden;
        border: 1px solid#e0e7ff;
        padding-right: 6px;
        label {
            height: 40px;
            line-height: 40px;
            padding: 0 4px 0 12px;
        }
        .mat-form-field-wrapper {
            padding: 0;
            height: 40px;
            font-weight: bold;
            .mat-form-field-flex {
                height: 40px;
            }
            .mat-form-field-infix {
                border: 0;
                padding: 0;
                height: 40px;
                width: 120px;
                select {
                    padding: 0;
                    height: 40px;
                    top: 0;
                    margin-bottom: 0;
                }
            }
        }
        .mat-form-field-appearance-legacy {
            .mat-form-field-underline {
                display: none;
            }
        }
        .mat-form-field-underline {
            display: none;
        }
    }
}

.ras-modal__actions {
    height: 56px;
    .status {
        height: 40px;
        display: inline-flex;
        overflow: hidden;
        border: 1px solid#e0e7ff;
        padding-right: 6px;
        label {
            height: 40px;
            line-height: 40px;
            padding: 0 4px 0 12px;
        }
        .mat-form-field-wrapper {
            padding: 0;
            height: 40px;
            font-weight: bold;
            .mat-form-field-flex {
                height: 40px;
            }
            .mat-form-field-infix {
                border: 0;
                padding: 0;
                height: 40px;
                width: 120px;
                select {
                    padding: 0;
                    height: 40px;
                    top: 0;
                    margin-bottom: 0;
                }
            }
        }
        .mat-form-field-appearance-legacy {
            .mat-form-field-underline {
                display: none;
            }
        }
        .mat-form-field-underline {
            display: none;
        }
    }
}