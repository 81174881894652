/**
 * TYPOGRAPHY
 *
 * Definition of typographic styles
 */

/* ------------------------------------ *\
   #HEADINGS
\* ------------------------------------ */

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;

    a {
        color: inherit;
    }
}

h1 {
    margin-bottom: $spacing-unit*2;
    font-size: $font-size-ll;
    line-height: 1.1;
    font-weight: $font-weight-bold;
    color: $color-text;
}

h2 {
    font-size: $font-size-l;
    line-height: 1;
    font-weight: $font-weight-bold;
}

h3 {
    font-size: $font-size-xl;
    line-height: 1.2;
}

h4 {
    font-size: $font-size-ml;
    line-height: 1.2;
}

h5 {
    font-size: $font-size-m;
    line-height: 1.2;
}

p {
    margin: 0;
    padding: 0;
    font-size: $font-size-m;
    font-weight: $font-weight-normal;
    line-height: 1.8;
}


/* ------------------------------------ *\
   #LINKS
\* ------------------------------------ */

a {
    color: $color-text-link;
    text-decoration: none;

    h1, h2, h3, h4, h5, h6 {
        color: inherit;
    }
}


/* ------------------------------------ *\
   #HR
\* ------------------------------------ */

hr {
    margin: 0;
    padding: 0;
    border: none;
    border-top: 1px solid $color-border-normal;
}


/* ------------------------------------ *\
   #MEDIA-QUERIES
\* ------------------------------------ */

@media all and (max-width: $breakpoint-ipad-portrait - 1) {
    p {
        font-size: $font-size-s;
    }

    h1 {
        margin-bottom: $spacing-unit;
        font-size: $font-size-l;
    }

    h2 {
        font-size: $font-size-ml;
    }
}

@media all and (max-width: $breakpoint-smartphone-landscape) {
}

@media all and (min-width: $breakpoint-ipad-portrait) {

}