/**
 * DASHBOARD-START
 *
 * Style for dashboard start
 */

/* ------------------------------------ *\
   #DASHBOARD-START
\* ------------------------------------ */

.ras-start {
    padding: $spacing-unit*2 0;

    .ras-start__form {
        padding: $spacing-unit;
        border-top: 2px solid $color-text;
        background: $color-background-light;

    }

    .ras-form-item {
        margin-bottom: $spacing-unit*2;
    }

    .ras-start__form-action {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: $spacing-unit*2 0;
    }

    .ras-form-datepicker{
        font-size: 1.5em;
    }


    .ras-radio {
        input[type="radio"] {
            & + label {
                color: $color-second-light;
                font-size: $font-size-s;

                &::before {
                    font-size: $font-size-ml;
                }
            }

            &:checked {
                & + label {
                    color: $color-text;

                    &:before {
                        color: $color-text;
                    }
                }
            }
        }
    }

}


/* ------------------------------------ *\
   #MEDIA-QUERIES
\* ------------------------------------ */

@media all and (max-width: $breakpoint-ipad-portrait - 1) {
}

@media all and (min-width: $breakpoint-ipad-portrait) {
    .ras-start {
        padding: $spacing-unit*4 0;

        .ras-start__form {
            padding: $spacing-unit*3;
        }

        .ras-start__form-action {
            padding: $spacing-unit*3 0;
        }
    }
}

@media all and (min-width: $breakpoint-ipad-portrait) and (max-width: $breakpoint-laptop-wide - 1) {
}

@media all and (min-width: $breakpoint-laptop-wide) {
}
