/**
 * HEADER
 *
 * Header style
 */


/* ------------------------------------ *\
   #HEADER
\* ------------------------------------ */

.ras-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $spacing-unit;
    border-bottom: 1px solid $color-border-normal;
    background: #ffffff;
    .ras-btn {
        margin-left: auto;
    }
    .ras-search-autocomplete {
        margin-left: auto;
    }
    .ng-star-inserted {
        margin-left: 12px;
    }
}

.ras-logo {
    display: inline-block;
    width: 137px;
    height: 20px;
    background-image: url(assets/images/logo.svg);
    background-position: center;
    background-repeat: no-repeat;
    img {
        display: none;
    }
}

.ras-header__user-area {
    display: flex;
    align-items: center;
    margin: 0 0 0 $spacing-unit * 3;
    padding: 0;
    list-style: none;
    .ras-header__user-notify {
        padding: 0 $spacing-unit * 1.5;
        font-size: $font-size-l;
        color: $color-text-form-placeholder;
        &:hover,
        &:focus {
            color: $color-text-hover;
        }
    }
    .ras-header__user-profile {
        display: inline-block;
        width: $spacing-unit * 4;
        height: $spacing-unit * 4;
        border-radius: 50%;
        overflow: hidden;
        background: $color-gray-01;
        .ras-header__user-img {
            margin: 6px 0px 0px 9px;
            font-size: 1.5em;
            color: $color-gray-04;
        }
    }
}


/* ------------------------------------ *\
   #MEDIA-QUERIES
\* ------------------------------------ */

@media all and (max-width: $breakpoint-ipad-portrait - 1) {
    .ras-header {
        .ras-btn {
            width: auto;
        }
    }
}

@media all and (min-width: $breakpoint-ipad-portrait) {
    .ras-header__user-area {}
}